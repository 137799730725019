






















import { Component, Prop, Vue } from "vue-property-decorator";
import SharedPlanListItem from "./ListItemOneRow.vue";

@Component({
    components: {
        SharedPlanListItem,
    },
})
export default class SharedPlanList extends Vue {
    @Prop() plans!: any[];
}
